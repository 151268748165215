<template>
  <div v-if="dossier.source_charge_contact && !paidPayment && dossier.invoice">
    Er is een factuur opgemaakt a {{ formatCurrency(dossier.source_price) }} met factuurnummer
    {{dossier.invoice.invoice_number}}.<br>De vervaldatum van deze factuur is
    {{formatDate(dossier.invoice.valid_till, 'longDate')}}.<br>
    U kunt de factuur eenvoudig en veilig online via iDEAL betalen.
    <br>
    <br>
    <div class="inline-toast bg-failure-light mb-3" v-if="showRejectedText">
      <i class="fa fa-close mx-2"></i>Uw betaling is helaas mislukt, probeer het nogmaals.
    </div>
    <!--<p>U kunt de factuur eenvoudig en veilig online via iDEAL betalen.<br>De betaling loopt via onze betaalprovider Mollie.</p>-->
    <button class="btn btn-success mb-2 text-start" @click.prevent="toMollie">Betaal uw factuur via iDEAL<i class="fa fa-arrow-right float-right ms-5 pt-1"></i></button>
    &nbsp;<img :src="'/img/ideal-logo.svg'" width="40" height="40" style="position: relative; bottom: 4px">&emsp;
    <button class="btn btn-primary mb-2 text-start" @click="downloadGeneratedPDF($event, 'factuur')">
      <i class="fa fa-file-pdf"></i>&ensp;Download factuur {{dossier.invoice.invoice_number}}
    </button>
    <br>
  </div>
  <div v-else-if="dossier.source_charge_contact && paidPayment">
    Uw factuur a {{ formatCurrency(dossier.source_price) }} met factuurnummer
    {{dossier.invoice.invoice_number}} is reeds voldaan.
    <br><br>
    <button class="btn btn-primary mb-2 text-start d-block" @click="downloadGeneratedPDF($event, 'factuur')">
      <i class="fa fa-file-pdf"></i>&ensp;Download factuur {{dossier.invoice.invoice_number}} (betaald op {{formatDate(paidPayment.created_at, 'longDate')}})
    </button>
    <br>
    <div class="inline-toast bg-success-light">
      <i class="fa fa-check mx-2"></i>Hartelijk dank, uw betaling is voltooid.
    </div>
  </div>
</template>

<script>
  import dossierservice from '@/services/contact/DossierService'

  export default {
    name: 'sourcePaymentDossier',
    props: {
      dossier: {},
      selectedQuote: {}
    },
    data(){ return{ showRejectedText: false } },
    computed: {
      paidPayment() {
        return this.dossier.payments.filter(function (q) {
          return q.payment_status == 'paid'
        })[0]
      }
    },
    methods: {
      toMollie() {
        dossierservice.paySource(this.dossier.id).then(response => {
          window.location.href = response.data.paymentUrl;
        }).catch(error => {
          this.$toast.error('Error while connecting to MollieServices')
        })
      },
      downloadGeneratedPDF(e, type) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, type)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            
            let contactFullName = this.dossier.contact.full_name
            if (type == 'factuur') {
              contactFullName = contactFullName + ' - Factuur #' + this.dossier.invoice.invoice_number
            }
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, contactFullName, type));
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      checkIfRejected(){
        const latestReject = this.dossier.payments.findLast(function (q) { 
            return (q.payment_status == 'failed' || q.payment_status == 'canceled') && ((new Date() - new Date(q.updated_at)) < 600000)
        });
        if(latestReject){
          if(!sessionStorage.getItem("payment-rejected")){
            sessionStorage.setItem("payment-rejected", latestReject.id);
            this.showRejectedText = true;
          }
        }
      }
    },
    mounted() {
      sessionStorage.removeItem('signature-main');
      sessionStorage.removeItem('signature-partner');
      sessionStorage.removeItem('partnerSameAddress');
      sessionStorage.removeItem('lhk');
      sessionStorage.removeItem('hasPartner');
      //To make sure we have the invoice data available
      window.setTimeout(() => {
        if(!this.dossier.invoice){
          dossierservice.getDossier(this.dossier.id).then(response => {
            this.$parent.dossier.invoice = response.data.invoice;
            this.checkIfRejected();
          });
        }
        else{
          this.checkIfRejected();
        }
      }, 200);
    }
  }
</script>