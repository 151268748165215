<template>
  <section>
    <h3>Stap 4 - Uploaden documenten</h3>
    <!--<p class="">In deze stap vragen wij u drie typen documenten te uploaden.</p>-->
    <br />
      <UploadModule
        :dossier="dossier"
        :title="'Legitimatiebewijs aanvrager:<br> ' + dossier.contact.addressed_name + ' ' + formatDate(dossier.contact.birth_date, 'shortDate')"
        :description="getDescription(1)"
        :uploadText="'Sleep uw bestand(en) hier of klik om een geldig legitimatiebewijs<br> van u ('+dossier.contact.full_name+') te uploaden...'"
        :type="'identification'"
        :owner="'contact'"
        ref="identification"
        :disabled="shouldDisable"
      />
      <UploadModule
        v-if="dossier.has_partner && dossier.partner && dossier.lijfrente_type != 'bancair'"
        :dossier="dossier"
        :title="'Legitimatiebewijs partner:<br> ' + dossier.partner.addressed_name + ' ' +  formatDate(dossier.partner.birth_date, 'shortDate')"
        :description="getDescription(2)"
        :uploadText="'Sleep uw bestand(en) hier of klik om een geldig legitimatiebewijs<br> van uw partner ('+dossier.partner.full_name+') te uploaden...'"
        :type="'identificationPartner'"
        :owner="'contact'"
        ref="identificationPartner"
        :disabled="shouldDisable"
      />
      <UploadModule
        :dossier="dossier"
        :title="'Bankidentificatie'"
        :description="getDescription(3)"
        :type="'bankIdentification'"
        :owner="'contact'"
        ref="bankIdentification"
        :disabled="shouldDisable"
      />
      <UploadModule
        :dossier="dossier"
        :title="'Oude polissen (niet verplicht)'"
        :description="'Indien u beschikt over uw oude polissen kunt u deze hier uploaden. Deze worden toegevoegd aan uw dossier.'"
        :type="'oldPolis'"
        :owner="'contact'"
        ref="oldPolis"
        :disabled="shouldDisable"
        v-if="dossier.pension_type === null"
      />
    <button class="btn btn-secondary" @click="this.$router.push('polis')" :disabled="shouldDisable">&larr;&ensp; Vorige stap</button>
    <button class="btn btn-success float-end" v-on:click="submitFiles()" :disabled="shouldDisable">Ga verder naar stap 5 &ensp;<i class="fa fa-arrow-right"></i></button>
  </section>
</template>

<script>
  import UploadModule from '@/components/dossier/UploadModule'
  import dossierservice from "@/services/contact/DossierService";

  export default {
    name: 'uploadDocumentsDossier',
    components: {
      UploadModule
    },
    props: {
      dossier: {}
    },
    computed: {
      selectedQuote() {
        return this.dossier.quotes.filter(function (q) {
          return q.selected
        })[0]
      },
      shouldDisable(){
          return this.dossier.max_step_id >= 23;
      }
    },
    methods: {
      submitFiles() {
        let notUploadedDocuments = [];
        let bankId = this.dossier.files.find(file => file.content_type == "bankIdentification") && this.$refs.bankIdentification.fileUploadCount > 0;
        let userId = this.dossier.files.find(file => file.content_type == "identification") && this.$refs.identification.fileUploadCount > 0;
        let partnerId = this.dossier.files.find(file => file.content_type == "identificationPartner") && this.$refs.identificationPartner.fileUploadCount > 0;
        let partnerNotrequired = false;
        if(!userId){
          notUploadedDocuments.push("Legitimatiebewijs aanvrager");
        }
        if(!this.dossier.has_partner || (!this.dossier.partner.insure_partner_pension && this.dossier.lijfrente_type == 'verzekering') || this.dossier.lijfrente_type == 'bancair') partnerNotrequired = true;
        if(!partnerId && !partnerNotrequired){
          notUploadedDocuments.push("Legitimatiebewijs partner");
        }
        if(!bankId){
          notUploadedDocuments.push("Bankidentificatie");
        }
        if(notUploadedDocuments.length > 0){
          this.$toast.error("Bestand(en) niet aangeleverd: " + notUploadedDocuments.join(", "), {duration: 5000 + (notUploadedDocuments.length * 1000)});
          return;
        } 
        dossierservice
          .updateStep(this.$parent.dossier.id, null, 40)
          .then((response) => {
            this.$parent.setStep(response.data.dossier_step_id);
            this.$router.push("sign");
          })
          .catch((error) => {
            this.$toast.error("Error while storing data");
          });
      },
      getDescription(type){
        if(type == 1 || type == 2){
          return `Wij verzoeken u vriendelijk om een geldige kopie legitimatie ${type == 2 ? 'van uw partner ' : ' '}te uploaden:
                  <br>• Paspoort (voorkant)
                  <br>• Rijbewijs of ID-kaart (voor- en achterkant).
                  <br>
                  <br>De volgende gegevens moeten zichtbaar zijn: voornaam, naam, geboortedatum, Burgerservicenummer, nummer identiteitsbewijs en de geldigheidsdatum van het document.`;
        }
        if(type == 3){
          return `Wij verzoeken u vriendelijk om een bewijs te uploaden waarop uw IBAN en volledige naam zichtbaar zijn. Dit mag een kopie zijn van:
                  ${this.dossier.pension_fund_history[0].origin.toLowerCase().includes('lijfrente') ? '' : '<br>• Bankpas'}
                  <br>• Bankafschrift
                  <br>• Schermafbeelding van uw internetbankieren. Uw saldo kunt u desgewenst onzichtbaar maken.`;
        }
      },
      getTitle(gender) {
        if (gender == 'male') {
          return 'De heer'
        } else if (gender == 'female') {
          return 'Mevrouw'
        } else {
          return ''
        }
      },
      getName(gender, initial, prefix, lastname) {
        let name = this.getTitle(gender) + ' ' + initial
        if (prefix) {
          name += ' ' + prefix.toLowerCase()
        }
        name += ' ' + lastname
        return name
      },
    },
    mounted(){
      if(sessionStorage.getItem('hasPartner') && sessionStorage.getItem('hasPartner') == 'true' && !this.dossier.has_partner){
        sessionStorage.removeItem('hasPartner');
        window.location.reload();
      }
    }
  }
</script>