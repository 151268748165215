import http from '@/http-common-contact'


const URL = '/api/contact'

class ContactService {
  updateContact(data) {
    return http.post(`${URL}/update`, data)
  }
}

export default new ContactService()