<template>
  <div class="timeline block" v-if="true"> 
    <div class="tl-item" v-for="step, stepindex in steps" :key="step.id" @click="setDossierStep(step)"
      :class="{ 'active': (step.id <= dossier.max_step_id || step.id == 7), 'completed': ((step.id < dossier.max_step_id) || (step.id == 25 && dossier.dossier_step_id >= 23)) }">
      <div class="tl-dot b-primary"></div>
      <div class="tl-content text-center" :class="{ 'clickable': step.id <= dossier.max_step_id }">
        <div class="font-dsmbold fs-5" :data-step="step.id">Stap {{stepindex + 1}}</div>
        <div style="font-size: 14px">{{step.action}}</div>
      </div>
    </div>
  </div>
  <div class="timeline block-alt" v-else>
    <div class="tl-item" v-for="step in settlementSteps" :key="step.id"
      :class="{ 'active': step.id <= maxSettlementStep, 'completed': step.id < maxSettlementStep }">
      <div class="tl-dot b-primary"></div>
      <div class="tl-content text-center">
        <div class="font-dsmbold fs-5" :data-step="step.id">{{step.title}}</div>
        <div style="font-size: 14px">{{step.action}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import dossierservice from '@/services/contact/DossierService'

  export default {
    name: 'DossierProgress',
    props: {
      dossier: {},
      showSettlementSteps: Boolean
    },
    data() {
      return {
        steps: [],
        settlementSteps: [{
          id: 0,
          title: 'Ingediend',
          action: 'Aanvraag en verzoek tot overmaken ingediend'
        },{
          id: 1,
          title: 'Goedgekeurd',
          action: 'Aanvraag en verzoek tot overmaken goedgekeurd'
        },{
          id: 2,
          title: 'Overgemaakt',
          action: 'Koopsom(men) overgemaakt'
        },{
          id: 3,
          title: 'Ontvangen',
          action: 'Koopsom(men) ontvangen'
        },{
          id: 4,
          title: 'Polis',
          action: 'Uw polis staat opgemaakt bij uw documenten'
        },],
        maxSettlementStep: 0
      }
    },
    computed: {
      paidPayment() {
        return this.dossier.payments.filter(function (q) {
          return q.payment_status == 'paid'
        })[0]
      }
    },
    methods: {
      getAllSteps() {
        dossierservice.getDossierSteps().then(response => {
          this.steps = response.data;
          this.$nextTick(() => {
            const el = document.querySelector('.tl-item.active');
            if(el){
              el.scrollIntoView({block: "nearest", inline: "center"});
            }
          });
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user
        });

      },

      setDossierStep(step){
        if(step.id <= this.dossier.max_step_id) this.$parent.redirectToPage(step);
      }
    },
    mounted() {
      this.getAllSteps()
    }
  }
</script>