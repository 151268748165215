<template>
  <h3>Stap 3 - Herkomst koopsom</h3>
  <p class="">
    Bij welke verzekeringsmaatschappij - pensioenfonds komt uw pensioenkapitaal vrij (waar staat het pensioenkapitaal nu). Wij zullen aan de hand van de door u ingevulde gegevens (polisnummer, referentienummer of klantnummer) een uitkeringsverzoek opstellen. Het totaalbedrag moet overeenkomen met de door u gekozen koopsom.
    <br>
    Kapitaal in hele getallen invoeren.
  </p>

  <div class="row" v-show="!isMobile">
    <div class="col-sm" style="min-width: 27.5%">
      <h6 class="d-inline">Huidige Maatschappij</h6>
        <i class="fa fa-info-circle info-icon ms-1"></i>
        <span class="w-25">Bij deze maatschappij komt het pensioenkapitaal vrij.</span>
    </div>
    <div class="col-sm">
      <h6>Polis / refnr / klantnr</h6>
    </div>
    <div class="col-sm">
      <h6>Einddatum</h6>
    </div>
    <div class="col-sm">
      <h6>Kapitaal</h6>
    </div>
  </div>

  <div id="form" :class="{'mobile-polis': isMobile}">
    <div :class="{'row' : !isMobile}" v-for="(pensionFundHistory, index) in fundHistory" :key="pensionFundHistory.id">

      <div class="row pt-2" v-show="isMobile">
        <h6>Huidige Maatschappij</h6>
      </div>
      <div class="col-sm position-relative" :style="{'min-width': (isMobile ? '25%' : '27.5%')}">
        <i class="fa fa-close close-button-polis" :class="{'is-mobile' : isMobile}" @click="removePolis(index)"></i>
        <select class="form-select company-select" v-show="pensionFundHistory.pension_execution_company_id != 35" v-model="pensionFundHistory.pension_execution_company_id" required
               :class="{'is-valid': pensionFundHistory.pension_execution_company_id}" :disabled="shouldDisable">
          <option :value="null">Maak een keuze...</option>
          <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
        </select>
        <div class="input-group" v-if="pensionFundHistory.pension_execution_company_id == 35">
          <input class="form-control"  type="text" v-model="pensionFundHistory.pension_execution_company_name" placeholder="Voer naam in..." :disabled="shouldDisable"
                :class="{'is-valid': pensionFundHistory.pension_execution_company_name}" />
          <button class="btn btn-outline-secondary" type="button" @click="pensionFundHistory.pension_execution_company_id = null" :disabled="shouldDisable">&olarr;</button>
        </div>
      </div>
      
      <div class="row pt-2" v-show="isMobile">
        <h6>Polis / refnr / klantnr</h6>
      </div>
      <div class="col-sm">
        <input class="form-control" type="text" v-model="pensionFundHistory.polis_number" placeholder="" :class="{'is-valid': pensionFundHistory.polis_number}" required :disabled="shouldDisable" />
      </div>
      
      <div class="row pt-2" v-show="isMobile">
        <h6>Einddatum</h6>
      </div>
      <div class="col-sm">
        <input class="form-control is-valid" type="date" required style="max-height: 38px" :disabled="shouldDisable"
          :value="formatDate(pensionFundHistory.expire_date, 'isoDate')"
          @input="pensionFundHistory.expire_date = $event.target.valueAsDate" />
      </div>
      
      <div class="row pt-2" v-show="isMobile">
        <h6>Kapitaal</h6>
      </div>
      <div class="col-sm">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
          <input type="text" class="form-control" @change="roundNumber(pensionFundHistory.fund, index)" v-model="pensionFundHistory.fund" required :class="{'is-valid': pensionFundHistory.fund}" :disabled="shouldDisable" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm" :style="{'min-width': (isMobile ? '25%' : '27.5%')}"></div>
    <div class="col-sm"></div>
    <div class="col-sm mt-2">
      <strong>Totale koopsom</strong>
    </div>
    <div class="col-sm">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">€</span>
        </div>
        <input type="text" class="form-control" disabled :value="formatCurrency(polisSum, '', 0) + ',-'" :class="polisSum == totalPolisSum ? 'is-valid' : 'is-invalid'" />
        <div class="invalid-feedback">Totaal kapitaal moet uitkomen op {{formatCurrency(totalPolisSum, '€', 0)}},-</div>
      </div>

    </div>
  </div>
  <p class="">
    <button class="btn btn-tertiary" @click="addPolis" :disabled="shouldDisable">
      &plus;&ensp; Voeg extra koopsom toe
    </button>
  </p>
  <p class="">
    Als de definitieve koopsom afwijkt van het in de offerte/aanvraag gehanteerde bedrag wordt bij opmaak van uw polis de uitkering automatisch pro rato herrekend.
  </p>
  <p>
    <br /><br />
    <button class="btn btn-secondary" @click="this.$router.push('nawPayment')" :disabled="shouldDisable">&larr;&ensp; Vorige stap</button>
    <button class="btn btn-success float-end" @click="updatePolis" :disabled="shouldDisable">
      Ga verder naar stap 4 &ensp;<i class="fa fa-arrow-right"></i>
    </button>
  </p>
</template>

<script>
  import dossierservice from "@/services/contact/DossierService";

  export default {
    name: "polisDossier",
    props: {
      dossier: {},
    },
    computed: {
      polisSum: function () {
        let sum = 0;
        this.fundHistory.forEach((pensionFundHistory) => {
          if(pensionFundHistory.fund)
            sum += parseFloat(pensionFundHistory.fund);
        });

        return sum;
      },
      shouldDisable(){
          return this.dossier.max_step_id >= 23;
      }
    },
    data() {
      return {
        companies: [],
        fundHistory: [],
        totalPolisSum: 0,
        isMobile: false
      };
    },
    methods: {
      roundNumber(fund, index){
        let fundNumber = fund.replace(",", ".");
        this.fundHistory[index].fund = Math.round(fundNumber)
      },
      updatePolis() {
        if (this.polisSum != this.totalPolisSum) return false;
        let form = document.getElementById("form");
        // Add 'is-invalid' class to all fields that the browser deems invalid
        let invalidGroup = form.querySelectorAll(":invalid");
        for (let j = 0; j < invalidGroup.length; j++) {
          invalidGroup[j].classList.add('is-invalid');
          //Add event listener to remove the class again after the user changes it
          invalidGroup[j].addEventListener("input", e => e.target.classList.remove("is-invalid"), {
            once: true
          });
        }
        // Check if there are fields with the 'is-invalid' class, then don't continue submitting
        let invalid = form.querySelectorAll(".is-invalid");
        if (invalid.length) return false;
        let noCompanySelected = false;
        let companyFields = document.querySelectorAll('.company-select');
        this.fundHistory.forEach((pensionFundHistory, pIndex) => {
          if(pensionFundHistory.pension_execution_company_id === null || (pensionFundHistory.pension_execution_company_id == 35 && !pensionFundHistory.pension_execution_company_name)){
             noCompanySelected = true;
             let field = pensionFundHistory.pension_execution_company_id == 35 ? companyFields[pIndex].nextElementSibling.firstElementChild : companyFields[pIndex];
             field.classList.add('is-invalid');
             field.addEventListener("change", e => e.target.classList.remove("is-invalid"), {
                once: true
            });
          }
        })
        if(noCompanySelected) return false;

        dossierservice
          .updatePensionHistory(this.dossier.id, this.fundHistory)
          .then((response) => {
            this.$parent.dossier.pension_fund_history = response.data.pension_fund_history
            // this.$toast.success("Polis information has been updated");
            this.$parent.setStep(response.data.dossier_step_id);
            this.$router.push("documents");
          })
          .catch((error) => {
            this.$toast.error("Error while storing data");
          });
      },
      getPolisCompanies() {
        const isPension = this.dossier.pension_fund_history[0].origin == 'Pensioen' || this.dossier.pension_type != null;
        const isLijfrente = this.dossier.lijfrente_type == "verzekering";
        dossierservice
          .getPolisCompanies(isPension, isLijfrente)
          .then((response) => {
            this.companies = response.data;
            this.companies.sort(function(a, b) { 
              if(a.code == 'Overig') return 1;
              if(b.code == 'Overig') return -1;
              return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
            });
          })
          .catch((error) => {
            this.$toast.error("Failed to retreive companies");
          });
      },
      addPolis() {
        this.fundHistory.push({
          expire_date: this.dossier.start_date_payroll,
          origin: "AppleTreePortal",
          fund: this.totalPolisSum - this.polisSum,
          pension_execution_company_id: null
        });
      },
      removePolis(index) {
        if(!this.shouldDisable) this.fundHistory.splice(this.fundHistory.indexOf(index), 1);
      },
    },
    mounted() {
      this.getPolisCompanies();
      this.fundHistory = JSON.parse( JSON.stringify(this.dossier.pension_fund_history) );

      this.dossier.pension_fund_history.forEach((pensionFundHistory) => {
        this.totalPolisSum += parseFloat(pensionFundHistory.fund);
      });
      let fundSubtotal = 0;
      this.fundHistory.forEach((pensionFundHistory) => {
        if(pensionFundHistory.expire_date == null){
          pensionFundHistory.expire_date = this.dossier.start_date_payroll;
        }
        if(pensionFundHistory.pension_execution_company_id == 35 && pensionFundHistory.pension_execution_company_name == null){
          pensionFundHistory.pension_execution_company_id = null;
        }
        if(pensionFundHistory.pension_execution_company_id == null){
          pensionFundHistory.fund = "";
        }
        let thisfund = parseFloat(pensionFundHistory.fund);
        if(!pensionFundHistory.fund){
          if(isNaN(this.totalPolisSum)) return;
          pensionFundHistory.fund = this.totalPolisSum - fundSubtotal;
        }
        else{
          fundSubtotal += thisfund;
        }
      });
      if(isNaN(this.totalPolisSum))
        this.totalPolisSum = 0;
      if(!window.totalPolisSum || window.dossierId != this.dossier.id){
        window.totalPolisSum = this.totalPolisSum;
        window.dossierId = this.dossier.id;
      }
      else
        this.totalPolisSum = window.totalPolisSum;
      
      this.isMobile = window.innerWidth < 600;
      addEventListener("resize", (event) => {
        this.isMobile = window.innerWidth < 600;
      });
    },
  };
</script>
<style scoped>
  .td-select-box {
    width: 27em;
  }

  .polisError input {
    background-color: rgb(236 72 72);
  }
</style>