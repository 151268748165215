<template>
    <div v-if="isOfferExpired()">
      <h5>De offerte van {{selectedQuote.provider}} is verlopen</h5>
      <p>Neem contact op met Apple Tree.</p>
      <br />
      <button class="btn btn-secondary" @click="this.$router.push('quote')">&larr;&ensp; Vorige stap</button>
    </div>
    <template v-else>
      <h5 class="mt-5"><span v-if="selectedQuote.company_quote_file_name">Accepteer</span><span v-else>Wacht op</span> de offerte van {{selectedQuote.provider}}</h5>
      <p>
        <button class="btn btn-primary mb-2  text-start" :disabled="!selectedQuote.company_quote_file_name" @click="downloadQuoteFile">
          <i class="fa fa-file-pdf"></i>&ensp;Download {{selectedQuote.provider}} offerte, {{formatDate(new Date(), 'longDate')}}
        </button><br />
      </p>

      <div class="form-check" v-if="selectedQuote.company_quote_file_name">
        <input class="form-check-input" v-bind:class="{'is-invalid': validate && !this.acceptOffer}" type="checkbox" value="" id="flexCheckChecked1" :disabled="hasConfirmedOffer" required v-model="acceptOffer" :true-value="1" :false-value="0"/>
        <label class="form-check-label" for="flexCheckChecked1">
          Ik kies voor de aanbieding van {{selectedQuote.provider}} a {{ formatCurrency(selectedQuote.payment_special_offer ? selectedQuote.payment_special_offer : selectedQuote.payment) }}  
          <span v-if="selectedQuote.payment_low">
            (na {{ dossier.pension_type_high_years }} jaar:
            {{ formatCurrency(selectedQuote.payment_low_special_offer ? selectedQuote.payment_low_special_offer : selectedQuote.payment_low) }})
          </span> {{ formatMonthPeriod(dossier.payroll_terms) }}, volgens {{selectedQuote.provider}} offerte. Ik heb kennis genomen van de inhoud van deze offerte.
        </label>
        <div class="invalid-feedback">
          Geef uw akkoord op de aanbieding.
        </div>
        
      </div>
      <div v-else>
          AppleTree is bezig om de offerte aan te vragen bij {{selectedQuote.provider}}, Dit kan enkele dagen duren.
          <br/><br/>
          Je ontvangt een email als de offerte klaart staat.
      </div>
    <br/><br/>
      <button v-if="!hasConfirmedOffer" class="btn btn-secondary" @click="this.$router.push('quote')">&larr;&ensp; Vorige stap</button>
      <button class="btn btn-success float-end" @click="nextStep" v-if="selectedQuote.company_quote_file_name">
          Ga verder naar stap 2 &ensp;<i class="fa fa-arrow-right"></i>
      </button>
      <button class="btn btn-success float-end" disabled v-if="!selectedQuote.company_quote_file_name">
          Afwachtend op AppleTree
      </button>
  <div class="clearfix"></div>
  </template>
</template>

<script>
import dossierservice from "@/services/contact/DossierService";

export default {
  name: "AcceptOfferDossier",
  props: {
    dossier: {},
  },
  data() {
    return {
        validate: false,
        acceptOffer: false,
        hasConfirmedOffer: false
    };
  },
  computed: {
        selectedQuote() {
        return this.dossier.quotes.filter(function (q) {
            return q.selected
        })[0]
    }
  },
  methods: {
    downloadQuoteFile(e) {
      const selquote = this.selectedQuote;
      this.setButtonLoadingIcon(e.target, true);
      dossierservice
        .downloadQuoteFile(selquote.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = selquote.company_quote_file_name.includes('.pdf') ? selquote.company_quote_file_name  : (selquote.company_quote_file_name + ".pdf");
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          this.setButtonLoadingIcon(e.target, false);
        })
        .catch((error) => {
          this.$toast.error("Error while attempting to download pdf");
        });
    },
    nextStep() {
        this.validate = true
        let valid = true;
        if (!this.acceptOffer) valid = false;
        if(!valid) return false;

        if(this.hasConfirmedOffer){
          this.$router.push("nawPayment");
        }

        dossierservice
        .acceptQuoteOffer(this.dossier.id, this.selectedQuote.id)
        .then((response) => {
          this.$parent.setStep(response.data.dossier_step_id);
          this.hasConfirmedOffer = true;
          this.$parent.setOfferConfirmedStatus(true);
          this.$parent.dossier.accepted_quote_offer_date = response.data.accepted_quote_offer_date
          this.$router.push("nawPayment");
        })
        .catch((error) => {
          this.$toast.error("Error while accepting offer");
        });
        
    },
    isOfferExpired(){
      return this.selectedQuote && (new Date().toISOString() > this.selectedQuote.company_quote_expiration_date);
    }
  },
  mounted(){
    this.acceptOffer = this.$parent.dossier.accepted_quote_offer_date != null ? 1 : 0
    this.hasConfirmedOffer = this.$parent.hasConfirmedOffer
    if(this.hasConfirmedOffer){
      this.acceptOffer = 1;
    }
    this.$nextTick(() => window.scrollTo(0, document.body.scrollHeight));
  }
};
</script>