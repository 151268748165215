import http from '@/http-common-contact'


const URL = '/api'

class CountryService {
  getCountries() {
    return http.get(`${URL}/get-countries`)
  }
}

export default new CountryService()