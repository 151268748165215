<template>
   <br>
   <div id="your-choice-box">
      <div class="font-dsmbold fw-bold pt-1 pr-2"><i class="fa-regular fa-thumbs-up"></i>&ensp;Uw keuze</div>
      <table>
         <tr>
            <td class="align-top pr-2">Uw uitkering:</td>
            <td v-if="dossier.pension_type === 'hooglaag'" class="pb-2">
               <span class="highlow">Eerste {{dossier.pension_type_high_years}} jaar<br /></span>
               {{getPayment(selectedQuote, true)}}&nbsp;bruto {{formatMonthPeriod(dossier.payroll_terms)}}<br style="margin-bottom: 5px" />
               <span class="highlow">Na {{dossier.pension_type_high_years}} jaar<br /></span>
               {{getPayment(selectedQuote, false)}}&nbsp;bruto {{formatMonthPeriod(dossier.payroll_terms)}}
            </td>
            <td v-else class="pb-2">{{getPayment(selectedQuote, true)}}&nbsp;bruto {{formatMonthPeriod(dossier.payroll_terms)}}</td>
         </tr>
         <tr>
            <td class="pr-2">Aanbieder:</td>
            <td>
               <img v-if="selectedQuote.providing_company && selectedQuote.providing_company.LogoSVG" class="companyLogo" :src="selectedQuote.providing_company.LogoSVG" />
            </td>
         </tr>
      </table>
   </div>
</template>

<script>
   export default {
      name: 'ChosenProvider',
      props: {
         dossier: {}
      },
      computed: {
         selectedQuote() {
            return this.dossier.quotes.filter(function (q) {
               return q.selected
            })[0]
         }
      },
      methods: {
         getPayment(quote, isHigh = true){
            if(isHigh){
               if(quote.payment_special_offer) return this.formatCurrency(quote.payment_special_offer)
               return this.formatCurrency(quote.payment)
            }
            else{
               if(quote.payment_low_special_offer) return this.formatCurrency(quote.payment_low_special_offer)
               return this.formatCurrency(quote.payment_low)
            }
         }
      }
   }
</script>
<style scoped>
   img {
      width: 100%;
      margin-bottom: 15px;
   }
   #your-choice-box{
      background-color: rgba(255,255,255,0.7);
      border-radius: 10px;
      padding-top: .7rem
   }
   .pr-2{
      padding-left: 1rem;
   }
   .highlow{
      font-family: 'DM Sans', sans-serif;
      line-height: 1rem;
   }
</style>
<style lang="css">
  .companyLogo{
      width: 80px;
  }
</style>