<template>
  <div id="nav" class="contact-nav" v-if="showDevNavigation">
    <router-link :to="{ path: 'home' }">home</router-link> |
    <router-link :to="{ path: 'quote' }">quotes</router-link> |
    <router-link :to="{ path: 'offer' }">offers</router-link> |
    <router-link :to="{ path: 'nawPayment' }">naw</router-link> |
    <router-link :to="{ path: 'polis' }">polis</router-link> |
    <router-link :to="{ path: 'documents' }">documents</router-link> |
    <router-link :to="{ path: 'sign' }">onderteken</router-link> |
    <router-link :to="{ path: 'sourcePayment' }">betaling</router-link>
  </div>
  <section id="steps_section" v-if="dataFetched">
    <DossierProgress :dossier="dossier" ref="dossierprogress" :showSettlementSteps="showComponent('sourcePayment')"></DossierProgress>
  </section>

  <div class="container">
    <div class="row flex-row-reverse">
      <div class="col-lg-4 side-panel" id="dossier-details-client">
        <button v-if="showComponent('sourcePayment')" type="button" class="btn btn-success dossier-details-documents mb-3 w-100 text-start" data-bs-toggle="modal" data-bs-target="#documentsModal">
          Bekijk uw documenten <i class="fa fa-arrow-right float-right pt-1"></i>
        </button>
        <DossierDetailsSection :dossier="dossier" :showchosenprovider="dataFetched && !showComponent('quote')" ref="detailssection"></DossierDetailsSection>
      </div>
      <div class="col-lg-8">
        <div v-if="offerExpired">
          <h3>De offerte is verlopen</h3>
          <p>Neem contact op met Apple Tree.</p>
        </div>
        <section>
          <AcceptQuoteDossier v-if="showComponent('quote') || showComponent('offer')" :dossier="dossier" :visibleNextButton="showComponent('quote')" />
          <AcceptOfferDossier v-if="showComponent('offer')" :dossier="dossier" />
          <ContactAndPaymentInformation v-if="showComponent('nawPayment')" :dossier="dossier" />
          <PolisDossier v-if="showComponent('polis')" :dossier="dossier" />
          <UploadDocumentsDossier v-if="showComponent('documents')" :dossier="dossier" />
          <SignDossier v-if="showComponent('sign') || dossier.max_step_id >= 20" :dossier="dossier" :visible="showComponent('sign')" />
          <DocumentsOverviewDossier v-if="showComponent('sourcePayment')" :dossier="dossier" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import dossierservice from '@/services/contact/DossierService'
  import AcceptQuoteDossier from '@/components/dossier/AcceptQuoteDossier'
  import AcceptOfferDossier from '@/components/dossier/AcceptOfferDossier'
  import ContactAndPaymentInformation from '@/components/dossier/ContactAndPaymentInformation'
  import UploadDocumentsDossier from '@/components/dossier/UploadDocumentsDossier'
  import PolisDossier from '@/components/dossier/PolisDossier'
  import SignDossier from '@/components/dossier/SignDossier'
  import DocumentsOverviewDossier from '@/components/dossier/DocumentsOverviewDossier'
  import DossierProgress from '@/components/dossier/DossierProgress'
  import DossierDetailsSection from '@/components/dossier/DossierDetailsSection.vue'

  export default {
    name: 'MyDossier',
    data() {
      return {
        dossierId: null,
        hasConfirmedOffer: false,
        offerExpired: false,
        dossier: {
          dossier_number: null
        },
        dataFetched: false
      }
    },
    components: {
      AcceptQuoteDossier,
      ContactAndPaymentInformation,
      UploadDocumentsDossier,
      PolisDossier,
      SignDossier,
      DocumentsOverviewDossier,
      DossierProgress,
      AcceptOfferDossier,
      DossierDetailsSection
    },
    computed: {
      showDevNavigation() {
        if(this.$route.query.devheader == 'Sebivten1!') {
          return true
        }
        return !process.env.VUE_APP_IS_PRODUCTION
      }
    },
    methods: {
      getDossier(mounted = false) {
        this.dataFetched = false
        dossierservice.getDossier(this.dossierId).then(response => {
          this.dossier = response.data
          console.log(response.data)
          this.dataFetched = true;
          if(this.dossier.dossier_step_id == 23 || this.dossier.dossier_step_id == 25){
            this.dossier.max_step_id = 25;
          }
          //Sort offers by payment, taking special offers into account
          this.dossier.quotes.sort(function(a, b){
            const paymentA = a.payment_special_offer ? a.payment_special_offer : a.payment;
            const paymentB = b.payment_special_offer ? b.payment_special_offer : b.payment;
            return (parseFloat(paymentA) < parseFloat(paymentB)) ? 1 : -1;
          });

          this.dossier.show_partner = true;
          if(this.dossier.lijfrente_type == 'bancair'){
            this.dossier.show_partner = false;
          }
          if(this.dossier.lijfrente_type == 'verzekering' && this.dossier.partner_death_benefit != 70 && this.dossier.partner_death_benefit != 100){
            this.dossier.show_partner = false;
          }

          const selectedQuote = this.dossier.quotes.filter((q) => q.selected)[0];
          const date = new Date().toISOString();
          this.offerExpired = !this.dossier.dossier_accepted_date && selectedQuote && (date > selectedQuote.company_quote_expiration_date);

          if (mounted) {
            if(this.$route.query.devheader != 'Sebivten1!') {
              this.redirectToPage()
            }
          }
        }).catch(error => {
          //this.$toast.error('Failed to retreive dossier')
          console.log(error)
        })
      },
      showComponent(componentName) {
        if(this.offerExpired && componentName != 'quote') return false;
        return componentName === this.$route.params.stage && this.dataFetched;
      },
      setStep(step){
        this.dossier.dossier_step_id = step;
        if(!this.dossier.max_step_id || this.dossier.max_step_id < step){
          this.dossier.max_step_id = step;
        }
      },
      setOfferConfirmedStatus(isConfirmed){
        this.hasConfirmedOffer = isConfirmed;
      },
      redirectToPage(step = null) {
        let nextStep = step ? step.number : this.dossier.step.number;
        let nextStepId = step ? step.id : this.dossier.step.id;
        if(step){
          this.dossier.dossier_step_id = nextStepId;
        }
        if(this.dossier.dossier_step_id == 23 || this.dossier.dossier_step_id == 25){
          this.dossier.max_step_id = 25;
        }
        if(!this.dossier.max_step_id || this.dossier.max_step_id < nextStepId){
          this.dossier.max_step_id = nextStepId;
        }

        if(this.dossier.accepted_quote_offer_date != null){
          this.hasConfirmedOffer = true;
        }
        
        switch (nextStep) {
          case 10:
            if(this.hasConfirmedOffer){
              this.$router.push("offer")
            }
            else{
              this.$router.push("quote")
            }
            break;
          case 11:
            if(this.dossier.lijfrente_type == 'bancair'){
              this.hasConfirmedOffer = true
              this.$router.push("nawPayment")
            }
            else{
              this.$router.push("offer")
            }
            break;
          case 20:
            if (!this.hasConfirmedOffer && this.dossier.lijfrente_type != 'bancair' && this.dossier.quotes.find(element => element.active == 1 && element.selected == 1)){
              this.dossier.max_step_id = 7
              this.$router.push("offer")
            } else {
              this.$router.push("nawPayment")
            }
            break;
          case 30:
            this.$router.push("polis")
            break;
          case 40:
            this.$router.push("documents")
            break;
          case 58:
            this.$router.push("sign")
            break;
          case 60:
            this.$router.push("sourcePayment")
            this.getDossier()
            break;
          default:
            if (nextStep > 60) {
              this.$router.push("sourcePayment")
              this.getDossier()
            } else {
              this.$router.push("quote")
            }
        }
        this.$nextTick(() => {
          const el = document.querySelector('.tl-item.active');
          if(el){
            el.scrollIntoView({block: "nearest", inline: "center"});
          }
        });
      }
    },
    mounted() {
      this.dossierId = this.$route.params.dossierId
      this.hasConfirmedOffer = false
      this.getDossier(true)
    }
  }
</script>
<style>
  section {
    background: #fff;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 20px;
    text-align: left;
  }
</style>