<template>
  <h3>Stap 1 - Uw keuze voor een aanbieding</h3>
  U heeft van één van onze adviseurs per e-mail uw offerte ontvangen. Deze offerte kunt u hieronder
  nogmaals downloaden.
  <br /><br />

  <button class="btn btn-primary mb-2  text-start" @click="downloadDossierPDF">
    <i class="fa fa-file-pdf"></i>&ensp;Download Apple Tree offerte {{ dossier.contact.full_name }}, {{ formatDate(dossier.email_send_date, 'longDate') }}
  </button><br>

  <!-- <a href="#" class="btn btn-secondary">Download - Dienstverleningsdocument</a> -->
  <template v-if="this.$route.params.stage === 'quote'">
  <br /><br />
  <h5>Akkoord met onze offerte?</h5>

  <p>Geef hieronder aan van welke aanbieding u gebruik wil maken.</p>

  <br />

  <div id="quoteslist" v-if="dossier.investings_percentage == 0">
    <h5>Ik kies voor de aanbieding van:</h5>

    <div class="row bold border-bottom pb-2">
      <div class="col-auto" style="width: 40px"></div>
      <div class="companyLogo">Aanbieder</div>
      <div class="col-6 ms-5">Bruto uitkering</div>
    </div>
    <label class="row border-bottom" v-for="quote in filteredQuotes" :key="quote.id" :for="'quote_checkbox_' + quote.id">
      <div class="col-auto" style="width: 40px">
        <input class="form-check-input" type="radio" :value="quote.id" :id="'quote_checkbox_' + quote.id" :disabled="!visibleNextButton || shouldDisable" v-model="selectedQuote" @change="validate.chosenOffer = false" />
      </div>
      <div class="col-auto companyLogo">
        <img v-if="quote.providing_company && quote.providing_company.LogoSVG" class="companyLogo" :src="quote.providing_company.LogoSVG" :alt="quote.provider" />
        <span v-else>{{ quote.provider }}</span>
      </div>
      <div class="col-6 ms-5">
        {{ formatCurrency(quote.payment_special_offer ? quote.payment_special_offer : quote.payment) }}
        <span v-if="quote.payment_low">
          (na {{ dossier.pension_type_high_years }} jaar:
          {{ formatCurrency(quote.payment_low_special_offer ? quote.payment_low_special_offer : quote.payment_low) }})
        </span>
        <span>{{formatMonthPeriod(dossier.payroll_terms)}}</span>
      </div>
    </label>
    <div class="mt-2 text-danger" v-show="validate.chosenOffer">
      Kies a.u.b. een aanbieding.
    </div>
  </div>
  <div v-else>Jij gaat beleggen met {{ dossier.investings_percentage }}%</div>
  </template>
  <button class="btn btn-success float-end mt-2" @click="acceptQuote" v-show="visibleNextButton" :disabled="shouldDisable">
    Ga verder &ensp;<i class="fa fa-arrow-right"></i>
  </button>
  <div class="clearfix"></div>
</template>

<script>
  import dossierservice from "@/services/contact/DossierService";

  export default {
    name: "acceptQuoteDossier",
    props: {
      dossier: {},
      visibleNextButton: Boolean
    },
    data() {
      return {
        selectedQuote: "null",
        acceptedOffer: true,
        validate: {
          acceptedOffer: false,
          chosenOffer: false
        }
      };
    },
    computed: {
        isNotPension(){
            return this.dossier.pension_fund_history[0] && this.dossier.pension_fund_history[0].origin != "Pensioen";
        },
        shouldDisable(){
            return this.dossier.max_step_id >= 23;
        },
        filteredQuotes(){
            return (this.dossier.lijfrente_type && this.isNotPension) ? this.dossier.quotes.filter(q => (q.lijfrente_type == this.dossier.lijfrente_type || !q.lijfrente_type)) : this.dossier.quotes;
        }
    },
    methods: {
      acceptQuote() {
        /*if (!this.acceptedOffer) {
          this.validate.acceptedOffer = true;
          // this.$toast.error("Akkoord op offerte is vereist.");
        } else if (this.selectedQuote == 'null') {*/
        if (this.selectedQuote == 'null') {
          this.validate.chosenOffer = true;
          // this.$toast.error("Kies a.u.b. een aanbieding");
        } else {
          this.validate.acceptedOffer = false;
          this.validate.chosenOffer = false;
          console.log(this.dossier.id, this.selectedQuote);
          dossierservice
            .acceptQuote(this.dossier.id, this.selectedQuote)
            .then((response) => {
              const newDossier = response.data;
              this.$parent.dossier.accepted_quote_date = newDossier.accepted_quote_date
              this.$parent.dossier.dossier_step_id = newDossier.dossier_step_id
              this.$parent.dossier.quotes = newDossier.quotes
              this.$parent.dossier.accepted_quote_date = newDossier.accepted_quote_date
              if(this.$parent.dossier.lijfrente_type == 'bancair'){
                this.$router.push("nawPayment");
              }
              else{
                this.$router.push("offer");
              }
              
              this.$parent.dossier.quotes.sort(function(a, b){
                  const paymentA = a.payment_special_offer ? a.payment_special_offer : a.payment;
                  const paymentB = b.payment_special_offer ? b.payment_special_offer : b.payment;
                  return (parseFloat(paymentA) < parseFloat(paymentB)) ? 1 : -1;
              });
            })
            .catch((error) => {
              this.$toast.error("Error while storing data");
            });
        }
      },
      downloadDossierPDF(e) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateDocument(this.dossier.id, 'offerte')
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const d = this.dossier;
            const filename = this.setDownloadFileName(d.dossier_number, d.contact.full_name, 'offerte van Apple Tree');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
    },
    mounted() {
      this.acceptedOffer = (this.$parent.dossier.accepted_quote_offer_date != null || this.$parent.dossier.step.number > 20);
      this.$parent.dossier.quotes.forEach((quote) => {
        if (quote.selected) {
          this.selectedQuote = quote.id;
        }
      });
      this.$parent.dossier.quotes.sort(function(a, b){
          const paymentA = a.payment_special_offer ? a.payment_special_offer : a.payment;
          const paymentB = b.payment_special_offer ? b.payment_special_offer : b.payment;
          return (parseFloat(paymentA) < parseFloat(paymentB)) ? 1 : -1;
      });
      if ((this.selectedQuote == 'null' || this.$parent.dossier.step.number < 20) && this.$parent.dossier.quotes.length){
        this.selectedQuote = this.$parent.dossier.quotes[0].id;
      }
    },
  };
</script>